import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setWindowSize } from '../src/actions/actions'; // Import your action

function WindowSizeDetector() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 900; // Define your threshold for mobile/desktop view
      dispatch(setWindowSize(isMobile));
    };

    // Initial call
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  return null;
}

export default WindowSizeDetector;
