// store.js
import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Import Redux Thunk middleware if you're using it
import { windowSizeReducer, dataReducer } from '../reducers/reducers'; // Import both reducers

// Combine reducers into a root reducer
const rootReducer = combineReducers({
  windowSize: windowSizeReducer,
  data: dataReducer, // Add dataReducer to the combined reducer
  // Add more reducers if needed
});

// Create the Redux store with the root reducer and apply middleware (if needed)
const store = createStore(rootReducer, applyMiddleware(thunk)); // Apply middleware if using Redux Thunk

export default store;
