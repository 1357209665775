// actions.js
export const setWindowSize = (isMobile) => {
    return {
      type: 'SET_WINDOW_SIZE',
      payload: isMobile,
    };
  };

export const fetchData = () => async (dispatch) => {
  dispatch({ type: 'FETCH_DATA_REQUEST' });
  try {
    const response = await fetch('https://backend-traversal.vercel.app/api/data');
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    dispatch({ type: 'FETCH_DATA_SUCCESS', payload: data });
  } catch (error) {
    dispatch({ type: 'FETCH_DATA_FAILURE', payload: error.message });
  }
};
  