// reducers.js
export const initialState = {
    isMobile: false,
    data: [],
    loading: false,
    error: null,
  };
  
  export const windowSizeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_WINDOW_SIZE':
        return { ...state, isMobile: action.payload };
      default:
        return state;
    }
  };

  export const dataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_DATA_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_DATA_SUCCESS':
        return { ...state, data: action.payload, loading: false, error: null };
      case 'FETCH_DATA_FAILURE':
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };  
    