import './App.css';
import Routers from 'Router';
import WindowSizeDetector from 'WindowSizeDetector';
// import AppRouter from 'Router';

function App() {
  return (
    <>
      <WindowSizeDetector />
      <Routers />
    </>
  );
}

export default App;
